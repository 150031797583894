<template>
    <div>
        <v-btn v-if="!fetched[level]" :loading="fetching[level]" fab small disabled text class="text--secondary ml-2">
            <v-icon>mdi-information</v-icon>
        </v-btn>
        <v-menu v-else offset-y offset-overflow :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab small text v-bind="attrs" v-on="on" class="text--secondary ml-2">
                    <v-icon>mdi-information</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-list v-if="contacts.length === 0">
                    <v-list-item class="pr-10 opacity-7">
                        <v-list-item-icon>
                            <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>No contacts found</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list v-else-if="$isError(contacts)" subheader>
                    <v-subheader>{{ labels[level] }}</v-subheader>
                    <v-list-item class="pr-10 warning--text opacity-7">
                        <v-list-item-icon>
                            <v-icon color="warning">mdi-alert-circle-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Error during contact retreval</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list two-line subheader v-else>
                    <v-subheader>{{ labels[level] }}</v-subheader>
                    <v-list-item v-for="({ full_name, email }, j) in contacts" :key="`contact-${j}`">
                        <v-list-item-content>
                            <v-list-item-title>{{ full_name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <copy-to-clipboard :textToCopy="email" :iconButton="true"></copy-to-clipboard>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')

export default {
    name: 'ContactsMenu',
    components: { CopyToClipboard },
    props: {
        level: {
            type: String,
            required: true
        },
        rpId: Number,
        orgId: Number,
        spaceId: Number
    },
    data() {
        return {
            fetched: {
                rp: false,
                org: false,
                space: false
            },
            fetching: {
                rp: false,
                org: false,
                space: false
            },
            contacts: [],
            labels: {
                rp: 'Resource pool managers',
                org: 'Organization managers',
                space: 'Space administrators'
            }
        }
    },
    methods: {
        async getContacts() {
            this.fetched[this.level] = false
            this.fetching[this.level] = true
            try {
                const { data } = await this.$axios.get(this.contactListApi)
                this.contacts = data
            } catch (error) {
                this.contacts = error
            } finally {
                this.fetched[this.level] = true
                this.fetching[this.level] = false
            }
        },
        reset() {
            this.fetched[this.level] = false
        }
    },
    computed: {
        contactListApi() {
            if (this.level === 'org') return `/resource/${this.rpId}/org/${this.orgId}/contacts`
            if (this.level === 'space') return `/resource/${this.rpId}/space/${this.spaceId}/contacts`
            return `/resource/${this.rpId}/contacts`
        }
    },
    watch: {
        rpId(id) {
            if (id === null) {
                this.reset()
                return
            }
            if (this.level === 'rp') {
                this.getContacts()
            }
        },
        orgId(id) {
            if (id === null) {
                this.reset()
                return
            }
            this.getContacts()
        },
        spaceId(id) {
            if (id === null) {
                this.reset()
                return
            }
            this.getContacts()
        }
    }
}
</script>
